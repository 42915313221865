import { selectFirstUnmetConnectionRequirement } from '@/selectors'
import { ConnectionRequirement } from '../types'
import { useAppSelector } from '@/hooks'
import AlertMessageLink from '../AlertMessageLink'

type Props = {
  connectionRequirements: ConnectionRequirement[]
}

function getMessageContent(type: ConnectionRequirement) {
  const copy = {
    utility: {
      message: 'You haven’t finished connecting your utility.',
      linkText: 'Connect Your Utility',
      linkTo: '/connect-utility',
    },
    vehicle: {
      message: 'You haven’t connected your electric vehicle.',
      linkText: 'Connect Your Vehicle',
      linkTo: '/connect-vehicle',
    },
    thermostat: {
      message: 'You haven’t connected your thermostat.',
      linkText: 'Connect Your Thermostat',
      linkTo: '/connect-thermostat',
    },
    meter: {
      message:
        'You haven’t connected your home meter to optimize your energy usage.',
      linkText: 'Connect Your Meter',
      linkTo: '/utility-program-enrollment',
    },
    plan: {
      message: 'You haven’t connected your utility and added a plan.',
      linkText: 'Connect Your Utility',
      linkTo: '/connect-utility',
    },
    unpendingPlan: {
      message: 'Your plan is pending, please check back soon.',
      linkText: 'Plan Settings',
      linkTo: '/settings',
    },
    home: {
      message: 'You haven’t connected a home.',
      linkText: 'Add Home',
      linkTo: '/settings?action=home',
    },
  }

  return copy[type]
}

/**
 * Given a list of connection requirements, this component will display an alert message for the first one that is not met.
 * @param props
 * @param props.connectionRequirements - The connection requirements to alert the user about. The first one that is not met will be displayed.
 */
export default function ConnectionAlertMessage(props: Props) {
  const connectRequirement = useAppSelector((state) =>
    selectFirstUnmetConnectionRequirement(state, props.connectionRequirements),
  )

  if (!connectRequirement) {
    return null
  }

  const messageContent = getMessageContent(connectRequirement)

  return (
    <AlertMessageLink
      variant="info"
      linkTo={messageContent.linkTo}
      linkText={messageContent.linkText}
    >
      {messageContent.message}
    </AlertMessageLink>
  )
}
